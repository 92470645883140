AOS.init();

// =====================
// Date picker for booking search

$(document).ready(function() {
    $('.datepicker input').datepicker({
        format: "d M yyyy",
        todayBtn: "linked",
        autoclose: true,
        assumeNearbyYear: true,
        todayHighlight: true
    }).on('changeDate', function(e) {
        $(this).closest('.datepicker').find('.actualdatepicker').val(moment(e.date).format(
            'YYYY-MM-DD'));
    });
});
