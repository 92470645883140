
(function ($) {
    'use strict';

    /*======== 1. SMOOTH SCROLLING TO SECTION ========*/
    $('.scrolling  a[href*="#"]').on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      var target = $(this).attr('href');

      function customVeocity(set_offset) {
        $(target).velocity('scroll', {
          duration: 800,
          offset: set_offset,
          easing: 'easeOutExpo',
          mobileHA: false
        });
      }

      if ($('#body').hasClass('up-scroll') || $('#body').hasClass('static')) {
        customVeocity(2);
      } else {
        customVeocity(-90);
      }

    });

    /*======== 2. NAVBAR ========*/
    $(window).on('load', function () {

      var header_area = $('.header');
      var main_area = header_area.find('.nav-menuzord');
      var zero = 0;
      var navbarSticky = $('.navbar-sticky');

      $(window).scroll(function () {
        var st = $(this).scrollTop();
        if (st > zero) {
          navbarSticky.addClass('navbar-scrollUp');
        } else {
          navbarSticky.removeClass('navbar-scrollUp');
        }
        zero = st;

        if (main_area.hasClass('navbar-sticky') && ($(this).scrollTop() <= 600 || $(this).width() <= 300)) {
          main_area.removeClass('navbar-scrollUp');
          main_area.removeClass('navbar-sticky').appendTo(header_area);
          header_area.css('height', 'auto');
        } else if (!main_area.hasClass('navbar-sticky') && $(this).width() > 300 && $(this).scrollTop() > 600) {
          header_area.css('height', header_area.height());
          main_area.addClass('navbar-scrollUp');
          main_area.css({ 'opacity': '0' }).addClass('navbar-sticky');
          main_area.appendTo($('body')).animate({ 'opacity': 1 });
        }
      });

      $(window).trigger('resize');
      $(window).trigger('scroll');
    });

    /* ======== ALL DROPDOWN ON HOVER======== */
    if ($(window).width() > 991) {
      $('.navbar-expand-lg .navbar-nav .dropdown').hover(function () {
        $(this).addClass('').find('.dropdown-menu').addClass('show');
      }, function () {
        $(this).find('.dropdown-menu').removeClass('show');
      });
    }

    if ($(window).width() > 767) {
      $('.navbar-expand-md .navbar-nav .dropdown').hover(function () {
        $(this).addClass('').find('.dropdown-menu').addClass('show');
      }, function () {
        $(this).find('.dropdown-menu').removeClass('show');
      });
    }

    //============================== Menuzord =========================
    $('#menuzord').menuzord({
      indicatorFirstLevel: '<i class="fa fa-angle-down"></i>',
      indicatorSecondLevel: '<i class="fa fa-angle-right"></i>'
    });

    //============================== SELECT 2 =========================
    //$('.select2-select').select2();

    //============================== SELECTRIC =========================
    $('.select-option').selectric();

    /*======== Tooltip  ========*/
    $('[data-toggle="tooltip"]').tooltip();

    /*======== 11.PRICE SLIDER RANGER  ========*/
    var nonLinearStepSlider = document.getElementById('slider-non-linear-step');
    if (nonLinearStepSlider) {
      noUiSlider.create(nonLinearStepSlider, {
        connect: true,
        start: [125, 750],
        range: {
          'min': [0],
          'max': [1000]
        }
      });
    }

    var sliderValue = [
      document.getElementById('lower-value'), // 0
      document.getElementById('upper-value')  // 1
    ];

    // Display the slider value and how far the handle moved
    // from the left edge of the slider.
    var priceRange = document.getElementById('price-range');
    if (priceRange) {
      nonLinearStepSlider.noUiSlider.on('update', function (values, handle) {
        sliderValue[handle].innerHTML = '$' + Math.floor(values[handle]);
      });
    }

    /*======== COUNT INPUT (Quantity) ========*/
    $('.incr-btn').on('click', function (e) {
      var newVal;
      var $button = $(this);
      var oldValue = $button.parent().find('.quantity').val();
      $button.parent().find('.incr-btn[data-action=decrease]').removeClass('inactive');
      if ($button.data('action') === 'increase') {
        newVal = parseFloat(oldValue) + 1;
      } else {
        // Don't allow decrementing below 1
        if (oldValue > 1) {
          newVal = parseFloat(oldValue) - 1;
        } else {
          newVal = 0;
          $button.addClass('inactive');
        }
      }
      $button.parent().find('.quantity').val(newVal);
      e.preventDefault();
    });

    /*======== 18. COUNTER-UP ========*/
    var counter = $('#counter');
    if (counter.length) {
      var a = 0;
      $(window).scroll(function () {
        var oTop = counter.offset().top - window.innerHeight;
        if (a === 0 && $(window).scrollTop() > oTop) {
          $('.counter-value').each(function () {
            var $this = $(this),
              countTo = $this.attr('data-count');
            $({
              countNum: $this.text()
            }).animate({
              countNum: countTo
            },
            {
              duration: 5000,
              easing: 'swing',
              step: function () {
                $this.text(Math.floor(this.countNum));
              },
              complete: function () {
                $this.text(this.countNum);
              }
            });
          });
          a = 1;
        }
      });
    }

  })(jQuery);
